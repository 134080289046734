import { Injectable } from '@angular/core';
import { gql, Subscription } from 'apollo-angular';


const APPLICATION_STATUS_SUBSCRIPTION = gql`
subscription applicationEvents($applicationId: String){
    onApplicationEvent(input:{applicationId:$applicationId, applicationActivityType:"StatusChange"}) {
    data {
      applicationId
      description
    }
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class ApplicationStatusSubscriptionService extends Subscription {

  document = APPLICATION_STATUS_SUBSCRIPTION;
}
