/**
 * Enum for describing various Formly Hook functionality.
 * @readonly
 * @enum {string}
 */
export enum FormlyHook {
  /** @member {string} */
  /** Based on the data source provided, prepopulated the specified field with the provided value */
  Prepopulate = 'prepopulate',
  /** @member {string} */
  /** Tags a form field as the orchestrator of a geocoding group. Used in conjuction with
   * the geocodingAddressCity, geocodingAddressState,
   * geocodingAddressZip, geocodingAddressLatitude,
   * geocodingAddressLongitude form field properties */
  Geocode = 'geocode',
  MapToKey = 'mapToKey',
  LaunchPrintPreview = 'launchPrintPreview',
  /** @member {string} */
  /** Tags a form field as needing string text to be interpolated based on a known map of values asynchronously (such as a button click) */
  InterpolateStringAsync = 'interpolateStringAsync',
  /** @member {string} */
  /** Tags a form field as needing string text to be interpolated based on a known map of values  */
  InterpolateString = 'interpolateString',
  ImportAggregatorConstants = 'importAggregatorConstants',
  /** @member {string} */
  /** Copies data from one form section to another. When provided one parameter,
   * it will copy to the specified form section from the current section.
   * When provided two parameters the hook will copy data from the source
   * section to the target section. If provided a third parameter,
   * the copying will only take place if condition provided returns true  */
  CopySectionValues = 'copySectionValues',
  /** @member {string} */
  /** Use this hook when you want to map
   * arbitrary form fields into an object
   * for updating application form content
   * Usage: formModelContent('faciity.is_new_construction')
   *  */
  FormModelContent = 'formModelContent',

  interconnectionLookup = 'interconnectionLookup',

  customerAccountLookup = 'customerAccountLookup',

  prepopulateAsync = 'prepopulateAsync',

  generateRebateProjectId = 'generateRebateProjectId',

  populateAS8Description = 'populateAS8Description',

  linkIcApp = 'linkIcApp',
  /** @member {string} */
  /** Use this hook when you want to copy
   * arbitrary form fields values
   * from one part of the form to the current field
   * Usage: copyFrom('faciity.is_new_construction')
   *  */
  CopyFrom = 'copyFrom',
  InterpolateFileAndDownload = 'interpolateFileAndDownload',

  GetAnnualSystemCapacityEstimate = 'getAnnualSystemCapacityEstimate',

  RegisterFieldInstances = 'registerFieldInstances',

  conditionalCopy = 'conditionalCopy',

  JoinMulticheckboxValues = 'joinMulticheckboxValues',

  Min = 'min',

  CalcWithConstant = 'calcWithConstant',

  CheckFileType = 'checkFileType'
}
