import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
  } from '@angular/core';
  import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
  import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';
import { FeatureManagementService } from '@intellio/shared/services';
import { FormlyFieldConfig } from '@ngx-formly/core';

  @Component({
    selector: 'itc-feature-flag',
    template: `
      <div [hidden]="!shouldRender">
        <ng-template #fieldComponent></ng-template>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class FeatureFlagComponent
    extends IntellioFormlyWrapperComponent
    implements OnInit, OnDestroy
  {
    shouldRender = null;
    // defaulting to hide the field/section when the feature flag is OFF.
    // Users can override this to true via props if they want
    // to hide a field/section when a feature flag is ON.
    hideOn = false;

    constructor(
      protected notifierService: WrapperChangeDetectNotifierService,
      protected cd: ChangeDetectorRef,
      protected featureService: FeatureManagementService
    ) {
      super(notifierService, cd);
    }


    recursivelyDisable(field: FormlyFieldConfig){
      if(field.fieldGroup){
        field.fieldGroup.forEach(
          f => this.recursivelyDisable(f)
        );
      } else {
        field.formControl.disable({
          onlySelf: true,
          emitEvent: true,
        });
      }
    }


    recursivelyEnable(field: FormlyFieldConfig){
      if(field.fieldGroup){
        field.fieldGroup.forEach(
          f => this.recursivelyEnable(f)
        );
      } else {
        field.formControl.enable({
          onlySelf: true,
          emitEvent: true,
        });
      }
    }

    ngOnInit(): void {
      this.initChangeDetectSub();

      let featureFlag: string = this.field.props.featureFlag;
      if(this.field.props.hideOn !== null &&
        this.field.props.hideOn !== undefined
      ){
        this.hideOn = this.field.props.hideOn;
      }
      this.shouldRender = this.featureService.isFeatureActivated(featureFlag) !== this.hideOn;
      if(this.shouldRender){

        /*
        The below solution solves a specific bug from the BGE E-payments project on 11/12/2024
        - a payments form section was added to the L2 der form
        - the payments section has a disabled and an enabled version of the section based on the feature flag
        - these sections share the same form key 'payment'

        we cannot enable any field that has a field group
        this is because if two of the form sections with feature flags share the same key
        their form fields all get mashed into a single form control group
        but any nested field that has a field group also shares this same control group

        Consider the situation where required fields exist in the hidden and shown versions
        of a given form field.
        - the required fields will all share a form control group if the field is a form group
        - enabling/disabling will change the required fields validity for both sections
          - if the hidden section comes second in the form, all fields will be disabled and uneditable
          - if the shown section comes second, all fields will be enabled and it will fail validation
        */
        this.recursivelyEnable(this.field);

      } else {

        /*
        we cannot disable any field that has a field group
        this is because if two of the form sections with feature flags
        share the same key
        their form fields all get mashed into a single form control group
        but any nested field that has a field group also shares this same control group

        Consider the situation where required fields exist in the hidden and shown versions
        of a given form field.
        - the required fields will all share a form control group if the field is a form group
        - enabling/disabling will change the required fields validity for both sections
          - if the hidden section comes second in the form, all fields will be disabled and uneditable
          - if the shown section comes second, all fields will be enabled and it will fail validation
        */
        this.recursivelyDisable(this.field);
      }
    }

    ngOnDestroy() {
      this.destroyed$.next(null);
      this.destroyed$.complete();
    }
  }
