import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-formly-wrapper-panel',
  styleUrls: ['./section-wrapper.component.scss'],
  template: `
    <div class="section-wrapper">
      <hr *ngIf="!props.hideBreak" />
      <div
        [ngClass]="{
          'section-wrapper__form': true,
          'legacy-read-mode': props.legacyReadMode
        }"
      >
        <div class="section-wrapper__title">
          <span>{{ props.title }}</span>
          <span *ngIf="props.diff" class="section-wrapper__show-diff">{{
            props.diff
          }}</span>
        </div>
        <ng-container #fieldComponent></ng-container>
        <div></div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionWrapperComponent extends IntellioFormlyWrapperComponent {
  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  ngOnInit() {
    this.initChangeDetectSub();
  }
}
