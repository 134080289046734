import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Application,
  ApplicationListRequestParams,
  ApplicationListResponse,
  ApplicationQueueHeader,
  UDF,
  ApplicationPricingModel,
  IntellioHttpResponse,
  IntentOfGeneration,
  ArchiveApplicationRequest,
  OwnerChangeRequest,
  OwnerChangeRequestBulk,
  SpocChangeRequest,
  SpocChangeRequestBulk,
  Jurisdiction,
  ApplicationType,
  ApplicationStatuses,
  RuleAlerts,
  FilterConfig,
  ApplicationsFilter,
  GeneratorType,
  SystemLocation,
  ApplicationCreationResult,
  ApplicationTypeJurisdiction,
  StatusRecommendation,
  StatusChangeSchema,
  ApplicationTypeStatusBase,
  ApplicationTypeStatusJurisdiction,
  ApplicationVersionType,
} from '@intellio/shared/models';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService extends BaseService {
  readonly PAGE_SIZE = 25;
  customPaginatorLabel?: string;
  private readonly defaultApplicationResponse = {
    externalApplications: [],
    internalApplications: [],
    locations: undefined,
    totalApplications: 0,
    isDefaultResponse: true,
    isError: false,
  };

  private readonly defaultErrorResponse = {
    externalApplications: [],
    internalApplications: [],
    locations: undefined,
    totalApplications: 0,
    isDefaultResponse: false,
    isError: true,
  };

  private queueHeaderSubject$ = new BehaviorSubject<ApplicationQueueHeader[]>(
    []
  );
  private udfHeaderSubject$ = new BehaviorSubject<ApplicationQueueHeader[]>([]);
  private applicationResponseSubject$ =
    new BehaviorSubject<ApplicationListResponse>(
      this.defaultApplicationResponse
    );

  // Components should give this subject request params and have subscribed to getApplication$
  getApplicationSubject$ = new Subject<ApplicationListRequestParams>();
  getSearchApplicationSubject$ = new Subject<ApplicationListRequestParams>();

  constructor(
    client: HttpClient,
    appConfigService: AppConfigService,
    authService: AuthService,
    private accountService: AccountService
  ) {
    super(client, appConfigService, authService);
  }

  get queueHeader$() {
    return this.queueHeaderSubject$.asObservable();
  }

  get udfHeader$() {
    return this.udfHeaderSubject$.asObservable();
  }

  get applicationResponse$() {
    return this.applicationResponseSubject$.asObservable();
  }

  get getApplication$() {
    // Components should subscribe to this getter which runs when the respective subject gets a new value
    // The subject takes params for the http request and forwards those onto the http request in a switchMap
    // This behavior results in old requests being cancelled
    return this.getApplicationSubject$.pipe(
      switchMap((params) => this.getApplications(params))
    );
  }

  get getSearchApplication$() {
    return this.getSearchApplicationSubject$.pipe(
      switchMap((params) => this.getSearchApplications(params))
    );
  }

  resetApplicationResponse() {
    this.applicationResponseSubject$.next(this.defaultApplicationResponse);
  }

  private getApplications(
    params: ApplicationListRequestParams
  ): Observable<ApplicationListResponse> {
    // Clears the data immediately so old data isn't seen while new data is loading
    // This is a matter of preference and can be removed
    this.applicationResponseSubject$.next(this.defaultApplicationResponse);
    return this.get<ApplicationListResponse>(`/api/applications`, {
      params,
    }).pipe(
      catchError((err) => {
        return of(undefined);
      }),
      map((response) => {
        if (response === undefined) {
          this.applicationResponseSubject$.next(this.defaultErrorResponse);
          return this.defaultErrorResponse;
        }
        this.applicationResponseSubject$.next(response.data);
        return response.data;
      })
    );
  }

  private getSearchApplications(
    params: ApplicationListRequestParams
  ): Observable<ApplicationListResponse> {
    // Clears the data immediately so old data isn't seen while new data is loading
    // This is a matter of preference and can be removed
    this.applicationResponseSubject$.next(this.defaultApplicationResponse);
    return this.get<ApplicationListResponse>(`/api/search/applications`, {
      params,
    }).pipe(
      catchError((err) => {
        return of(undefined);
      }),
      map((response) => {
        if (response === undefined) {
          this.applicationResponseSubject$.next(this.defaultErrorResponse);
          return this.defaultErrorResponse;
        }
        this.applicationResponseSubject$.next(response.data);
        return response.data;
      })
    );
  }

  getAllApplicationTypes() {
    const url = '/api/applications/types';
    return this.get<ApplicationType[]>(url);
  }

  getApplicationTypesForCurrentCategory() {
    const url = `/api/applications/categories/current/types`;
    return this.get<ApplicationType[]>(url);
  }

  getApplicationTypesDetails() {
    const url = `/api/applications/types/details`;
    return this.get<ApplicationType[]>(url);
  }

  getIntentsOfGeneration(appTypeId: string, jurisdictionId: string) {
    const url = `/api/intentsOfGeneration/types/${appTypeId}/jurisdictions/${jurisdictionId}`;
    return this.get<IntentOfGeneration[]>(url);
  }

  getAllIntentsOfGeneration() {
    const url = `/api/intentsOfGeneration`;
    return this.get<IntentOfGeneration[]>(url);
  }

  getAllApplicationTypeJurisdictions() {
    const url = `/api/applications/types/jurisdiction/all`;
    return this.get<ApplicationTypeJurisdiction[]>(url);
  }

  getAllApplicationTypeStatusJurisdictions() {
    const url = `/api/applications/types/jurisdiction/status/all`;
    return this.get<any[]>(url);
  }

  priceApplication(priceFieldValue, appId: string) {
    let params = new HttpParams();
    let options = {};
    params = params.append('applicationId', appId);
    params = params.append('nameplateCapacity', priceFieldValue);
    options = {
      params: params,
    };
    return this.get<ApplicationPricingModel>(
      `/api/applications/${appId}/pricing`,
      options
    );
  }

  createCollaborator(data) {
    return this.post<string>(`/api/account/collaborator`, data);
  }

  createApplicationAssociation(appId, data) {
    return this.post<string>(
      `/api/applications/${appId}/associated`,
      data
    ).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  copyApplicationFiles(appId, sourceId, filesToCopy) {
    const copyFilesFromSource = `/api/applications/${appId}/${sourceId}/copyFiles`;
    return this.put<string>(copyFilesFromSource, filesToCopy);
  }

  saveApplication(appId: string, model: unknown, appCapacity: number) {
    const url = `/api/applications/${appId}/content`;

    const data = {
      content: model,
    };

    if (appCapacity !== null) {
      return this.updateApplicationCapacity(appCapacity, appId).pipe(
        concatMap((data) => {
          return this.put<string>(url, data);
        })
      );
    } else {
      return this.put<string>(url, data); //TODO: might want to cache the updated form data somewhere
    }
  }

  submitApplication(appId: string) {
    const url = `/api/applications/${appId}/submit`;
    return this.put<string>(url, {});
  }

  updateApplicationCapacity(
    appCapacity,
    appId: string
  ): Observable<IntellioHttpResponse<string>> {
    let params = new HttpParams();
    let options = {};
    params = params.append('nameplateCapacity', appCapacity);

    options = {
      params: params,
    };
    return this.put<string>(
      `/api/applications/${appId}/updateappcapacity`,
      options
    );
  }

  addApplicationCollaborator(
    appId: string,
    userId: string,
    addToAssociatedApplications: boolean
  ) {
    let params = new HttpParams();
    params = params.append(
      'addToAssociatedApplications',
      String(addToAssociatedApplications)
    );
    let options = {};
    options = {
      params: params,
    };
    return this.put(
      `/api/applications/${appId}/collaborators/${userId}`,
      null,
      options
    );
  }

  getApplicationById(id: string): Observable<Application> {
    return this.get<Application>(`/api/applications/${id}`).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  getQueueHeaders(
    isExternal: boolean
  ): Observable<IntellioHttpResponse<ApplicationQueueHeader[]>> {
    return this.get<ApplicationQueueHeader[]>(
      `/api/tenant/queueHeaders?external=${isExternal}`
    );
  }

  getApplicationUdfs(): Observable<IntellioHttpResponse<UDF[]>> {
    return this.get<UDF[]>('/api/applications/udfs');
  }

  getApplicationUdfsWithCategory(categoryId: string) {
    return this.get<UDF[]>(`/api/applications/categories/${categoryId}/udfs`);
  }

  getStatusesForType(type) {
    const url = `/api/applications/types/${type}/statuses`;
    return this.get(url);
  }

  getStatusesForTypeJurisdiction(appTypeId, jurisdictionId) {
    const typeStatusUrl = `/api/applications/types/${appTypeId}/jurisdiction/${jurisdictionId}/statuses`;
    return this.get<ApplicationTypeStatusBase[]>(typeStatusUrl);
  }

  getTypeStatusJurisdictions(jurisdictionId) {
    const typeStatusJurisdictionUrl = `/api/applications/types/typestatusjurisdiction/${jurisdictionId}`;
    return this.get<ApplicationTypeStatusJurisdiction[]>(
      typeStatusJurisdictionUrl
    );
  }

  getRecommendedStatuses(
    currentStatus,
    appTypeId,
    jurisdictionId
  ): Observable<StatusRecommendation[]> {
    const url = `/api/applications/recommendations/${appTypeId}/${jurisdictionId}/${currentStatus}`;
    return this.get<StatusRecommendation[]>(url).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  getStatusChangeSchema(categoryId, versionNumber, schemaFileName) {
    const url = `/api/applications/statuschangeschema/${categoryId}?versionNumber=${versionNumber}&schemaFileName=${schemaFileName}`;
    return this.get<StatusChangeSchema>(url);
  }

  getApplicationTypes(): Observable<ApplicationType[]> {
    return this.get<ApplicationType[]>('/api/applications/types/full').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  getAllApplicationStatuses() {
    const url = '/api/applications/statuses';
    return this.get<ApplicationStatuses[]>(url);
  }

  getJurisdictions(): Observable<IntellioHttpResponse<Jurisdiction[]>> {
    const url = '/api/jurisdictions';
    return this.get<Jurisdiction[]>(url);
  }

  getExistingSystemLocations(): Observable<
    IntellioHttpResponse<SystemLocation[]>
  > {
    const url = '/api/applications/locations';
    return this.get<SystemLocation[]>(url);
  }

  getExistingSystemLocation(
    locationId
  ): Observable<IntellioHttpResponse<SystemLocation>> {
    const url = `/api/applications/locations/${locationId}`;
    return this.get<SystemLocation>(url);
  }

  getGeneratorTypes(): Observable<IntellioHttpResponse<GeneratorType[]>> {
    const url = '/api/generatorTypes';
    return this.get<GeneratorType[]>(url);
  }

  getAllApplicationAlerts() {
    const url = `/api/rulealerts?isExternal=${this.accountService.currentUser?.isExternal}`;
    return this.get<RuleAlerts[]>(url);
  }

  getTenantFilterConfig() {
    const url = `/api/applications/queue/filters/configuration?isExternal=${this.accountService.currentUser?.isExternal}`;
    return this.get<FilterConfig>(url);
  }

  updateArchiveStatus(data, appId) {
    const url = `/api/applications/${appId}/archive`;
    return this.put<ArchiveApplicationRequest>(url, data);
  }

  updateNewDaysRemaining(data, appId, statuses) {
    const url = `/api/applications/${appId}/statuses/${statuses}/extension/daysRemaining`;
    return this.put<number>(url, data);
  }

  updateNewApplicationDueDate(data, appId, statuses) {
    const url = `/api/applications/${appId}/statuses/${statuses}/extension/applicationDueDate`;
    return this.put<Date>(url, data);
  }

  updateExtension(updateExtensionPayload, appId, statusId) {
    const url = `/api/applications/${appId}/statuses/${statusId}/extension/`;
    return this.post(url, updateExtensionPayload);
  }

  updateOwner(data) {
    const url = `/api/applications/owner`;
    return this.put<OwnerChangeRequest>(url, data);
  }
  updateOwnerBulk(data, params: ApplicationListRequestParams) {
    const url = `/api/applications/owner/bulk`;
    return this.put<OwnerChangeRequestBulk>(url, data, {
      params,
    });
  }

  updateSPOC(data) {
    const url = `/api/applications/initialSpoc`;
    return this.put<SpocChangeRequest>(url, data);
  }
  updateSPOCBulk(data, params: ApplicationListRequestParams) {
    const url = `/api/applications/initialSpoc/bulk`;
    return this.put<SpocChangeRequestBulk>(url, data, {
      params,
    });
  }

  getSavedFilters() {
    const url = `/api/applications/queue/filters`;
    return this.get<ApplicationsFilter[]>(url);
  }

  saveQueueFilter(data) {
    const url = `/api/applications/queue/filters`;
    return this.post<string>(url, data);
  }

  updateSavedFilter(data, filterId) {
    const url = `/api/applications/queue/filters/${filterId}`;
    return this.put<string>(url, data);
  }

  deleteSavedFilter(filterId) {
    const url = `/api/applications/queue/filters/${filterId}`;
    return this.delete(url);
  }

  createApplication(payload): Observable<ApplicationCreationResult> {
    const url = `/api/applications`;
    return this.post<ApplicationCreationResult>(url, payload).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  getAssociatedApplications(currentApplicationId: string, applicationAssociationId: unknown, includeContent?: boolean) {
    let getApplicationAssociationUrl =
      '/api/applications/' +
      currentApplicationId +
      '/associated/' +
      applicationAssociationId;

      if(includeContent !== null && includeContent !== undefined){
        getApplicationAssociationUrl = `${getApplicationAssociationUrl}?includeContent=${includeContent}`;
      }

    return this.get<any[]>(getApplicationAssociationUrl);
  }

  getApplicationsBySystem(systemId) {
    const url = '/api/systems/' + systemId + '/applications';
    return this.get<any[]>(url);
  }

  cloneApplication(appId: string, data: any) {
    const url = 'api/applications/' + appId + '/clone';
    return this.post<Application>(url, data);
  }

  getApplicationVersionDetails(includeContent: boolean, appId: string) {
    let params = new HttpParams();
    let options = {};
    params = params.append('includeContent', includeContent);
    options = {
      params: params,
    };
    const url = `/api/applications/` + appId + `/content/history`;
    return this.get<ApplicationVersionType[]>(url, options);
  }

  getApplicationVersionHistoryDetails(
    includeContent: boolean,
    appId: string,
    versionNumber: number
  ) {
    let params = new HttpParams();
    let options = {};
    params = params.append('includeContent', includeContent);
    options = {
      params: params,
    };
    const url =
      `/api/applications/` + appId + `/content/history/` + versionNumber;
    return this.get<ApplicationVersionType>(url, options);
  }
}
