export class Payment {
  id: string;
  type: string;
  status: string;
  amount: number;
  isElectronicPayment: boolean;
  confirmationNumber: number;
  description: string;
  transactionId: string;
  createdDate: string;
  paidOnDate: string;
  tokenId: string;
  invoiceId: string;
  currentlyAcceptsPayment: boolean;
}
