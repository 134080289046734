import { Observable } from 'rxjs';
import { TemplateRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import { Application, IApplicationContent } from '@intellio/shared/models';

const mmddyyyyRegex =
  /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
const datetimeRegex =
  /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}(.+)(AM|PM)(.*)$/;

// src and explanation for below regex @ https://stackoverflow.com/a/61320626
const utcRegex =
  /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|\-)(?:\d{2}):?(?:\d{2}))$/;

export const getChildObjectsByValue = (
  theObject: Record<string, any>,
  property: string,
  value: string
) => {
  let results = [];
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      const newResults = getChildObjectsByValue(theObject[i], property, value);
      results = results.concat(newResults);
    }
  } else {
    for (const prop in theObject) {
      if (prop == property) {
        if (theObject[prop] == value) {
          results.push(theObject);
          return results;
        }
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        const newResults = getChildObjectsByValue(
          theObject[prop],
          property,
          value
        );
        results = results.concat(newResults);
      }
    }
  }
  return results;
};

export const getChildObject = (
  theObject: Record<string, any>,
  property: string
) => {
  let result = null;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getChildObject(theObject[i], property);
      if (result) {
        break;
      }
    }
  } else {
    for (const prop in theObject) {
      // fieldArrays do not contain an object with a formControl
      if (prop === 'fieldArray') {
        continue;
      }
      if (prop == property) {
        if (theObject[prop]) {
          return theObject;
        }
      }
      if (
        (theObject[prop] instanceof Object &&
          !(
            theObject[prop] instanceof Observable ||
            theObject[prop] instanceof Function ||
            theObject[prop] instanceof TemplateRef ||
            theObject[prop] instanceof UntypedFormControl ||
            theObject[prop] instanceof UntypedFormGroup
          )) ||
        theObject[prop] instanceof Array
      ) {
        result = getChildObject(theObject[prop], property);
        if (result) {
          break;
        }
      }
      continue;
    }
  }
  return result;
};

export const interpolateStringValues = (str, mapping) => {
  const regex = /{{[^}]*}}/g;
  const matches = str.match(regex);
  let copy = str;
  matches.forEach((m) => {
    const len = m.length;
    const cleaned = m.substring(2, len - 2).trim();
    let val = mapping[cleaned];
    if (val === undefined || val === null || val === '') {
      val = '________';
    }
    copy = copy.replace(m, val);
  });
  return copy;
};

export const getChildObjects = (theObject: any, property: string) => {
  let results = [];
  if (theObject instanceof Array) {
    for (const theObjectItem of theObject) {
      const newResults = getChildObjects(theObjectItem, property);
      results = results.concat(newResults);
    }
  } else {
    for (const prop in theObject) {
      if (prop === property) {
        if (theObject[prop]) {
          results.push(theObject);
          continue;
        }
      }
      if (
        (theObject[prop] instanceof Object &&
          !(
            theObject[prop] instanceof Observable ||
            theObject[prop] instanceof Function ||
            theObject[prop] instanceof TemplateRef ||
            theObject[prop] instanceof UntypedFormControl ||
            theObject[prop] instanceof UntypedFormGroup
          )) ||
        theObject[prop] instanceof Array
      ) {
        const newResults = getChildObjects(theObject[prop], property);
        results = results.concat(newResults);
      }
    }
  }
  return results;
};

export const getChildObjectsWithPath = (
  theObject: any,
  property: string,
  path: string = ''
) => {
  let results = [];
  if (theObject instanceof Array) {
    for (const theObjectItem of theObject) {
      const newResults = getChildObjectsWithPath(theObjectItem, property, path);
      results = results.concat(newResults);
    }
  } else {
    for (const prop in theObject) {
      if (prop === property) {
        if (theObject[prop]) {
          results.push({
            [path]: theObject,
            path,
            value: theObject,
          });
          continue;
        }
      }
      if (
        (theObject[prop] instanceof Object &&
          !(
            theObject[prop] instanceof Observable ||
            theObject[prop] instanceof Function ||
            theObject[prop] instanceof TemplateRef
          )) ||
        theObject[prop] instanceof Array
      ) {
        const newResults = getChildObjectsWithPath(
          theObject[prop],
          property,
          theObject.key ? path.concat(theObject.key, '.') : path
        );
        results = results.concat(newResults);
      }
    }
  }
  return results;
};

export const getValueOnObjectFromStringProperty = (
  stringProperty: string,
  object: unknown
) => {
  if (!object) return null;
  if (stringProperty === null || stringProperty === undefined) {
    return null;
  }
  return _.get(object, stringProperty);
};

export const deleteKeyOnObjectFromStringProperty = (stringProperty, object) => {
  if (!object || Object.keys(object).length === 0) {
    return;
  }
  if (stringProperty === undefined || stringProperty === null) {
    return;
  }
  const path = stringProperty.split('.');
  let current = object;
  for (let i = 0; i < path.length; i++) {
    if (i + 1 == path.length) {
      delete current[path[i]];
    } else {
      if (current[path[i]]) {
        current = current[path[i]];
      } else {
        continue;
      }
    }
  }
};

export const getFormRoot = (field: FormlyFieldConfig) => {
  let root = field;
  let isRoot = false;
  while (!isRoot) {
    if (!root.parent) {
      isRoot = true;
    } else {
      root = root.parent;
    }
  }
  return root;
};

export const fetchFormObjectByAttribute = (formRoot, attribute) => {
  let target = null;
  for (let i = 0; i < formRoot.length; i++) {
    target = getChildObject(formRoot[i].fieldGroup, attribute);
    if (target !== null) {
      break;
    }
  }
  return target;
};

//checks if conditional is present and true
export const shouldBeShown = (
  formGroup: UntypedFormGroup,
  keyPath: string,
  model
): boolean => {
  if (formGroup['_fields'] === undefined) {
    return true;
  }
  const props = formGroup['_fields'][0].props;
  const conditions = props.condition;
  const conditionOptions = props.conditionOptions;

  if (!conditions) {
    //no conditions present, control should be shown
    return true;
  }

  const conditionResults = [];

  for (const index in conditions) {
    const condition = conditions[index];

    //find the value the condition checks
    let value;
    if (condition.keyIsGlobal) {
      value = getValueOnObjectFromStringProperty(condition.key, model);
    } else {
      const tempValues = keyPath.substring(1).split('.'); //substring removes prepended .
      tempValues.splice(tempValues.length - 1, 1); // remove last element
      const modelKey = `${tempValues.join('.')}.${condition.key}`;
      value = getValueOnObjectFromStringProperty(modelKey, model);
    }

    if (condition.operator == '!' && condition.value == value) {
      //condition not met, hide field
      conditionResults[index] = false;
      return false;
    } else if (condition.value != value) {
      //condition not met, hide field
      conditionResults[index] = false;
      return false;
    } else {
      //no false conditions found, control should be shown
      conditionResults[index] = true;
    }
  }

  return conditionOptions?.operator === '||'
    ? conditionResults.some((res) => res)
    : conditions.every((res) => res);
};

export const markVisibleControlsAsTouched = (
  group,
  keyPath,
  model,
  markAsHidden = false
) => {
  //for each control in the dictionary
  //else it is a FormControl
  for (const controlKey in group.controls) {
    const control = group.controls[controlKey];
    //if the sub control has deeper sub controls then it is a FormGroup
    if (control.controls) {
      const formGroup = control as UntypedFormGroup;
      const hideFields =
        markAsHidden ||
        !shouldBeShown(formGroup, keyPath + '.' + controlKey, model);
      markVisibleControlsAsTouched(
        formGroup,
        keyPath + '.' + controlKey,
        model,
        hideFields
      );
    }
    //else it is a FormControl
    if (
      markAsHidden ||
      !shouldBeShown(control, keyPath + '.' + controlKey, model)
    ) {
      //dont show fields where the condition for showing them is not met
      //control.
      // TODO: when a control is hidden from view I need to mark it's status as valid
      control.setErrors(null);
    } else {
      const formControl = control as UntypedFormControl;
      formControl.markAllAsTouched();
    }
  }
};

//helper function to use instead of 'eval'
//for getting the value of an object where the properties are strings
//i.e. if you have a string of "prop1.prop2.prop3" and need to get the value
//of that string on "obj", as opposed to doing: eval(obj + '.prop1.prop2.prop3');
export const setValueOnObjectFromStringProperty = (
  stringProperty: string,
  object: any,
  value: any
) => {
  const path = stringProperty.split('.');
  let current = object;
  for (let i = 0; i < path.length; i++) {
    if (i + 1 == path.length) current[path[i]] = value;
    else {
      if (!current[path[i]]) {
        current[path[i]] = {};
      }
      current = current[path[i]];
    }
  }
  return current;
};

export const castLegacyDatesToNew = (modelNode) => {
  for (const property in modelNode) {
    const propVal = modelNode[property];
    if (typeof propVal === 'string') {
      if (mmddyyyyRegex.test(propVal) || datetimeRegex.test(propVal)) {
        const date = Date.parse(propVal);
        modelNode[property] = new Date(date);
      }
    } else if (typeof propVal === 'object') {
      castLegacyDatesToNew(propVal);
    }
  }
  return modelNode;
};

export const castDatesForTimepicker = (modelNode) => {
  for (const property in modelNode) {
    const propVal = modelNode[property];
    if (propVal) {
      if (typeof propVal === 'string' && utcRegex.test(propVal)) {
        modelNode[property] = new Date(propVal);
      } else if (typeof propVal === 'object') {
        castDatesForTimepicker(propVal);
      }
    }
  }
  return modelNode;
};

export const castNewDatesToLegacy = (modelNode) => {
  for (const property in modelNode) {
    const propVal = modelNode[property];
    if (propVal) {
      if (propVal instanceof Date) {
        modelNode[property] = propVal.toLocaleDateString();
      } else if (typeof propVal === 'string' && datetimeRegex.test(propVal)) {
        modelNode[property] = dayjs(propVal).format('M/D/YYYY h:mm:ss A Z');
      } else if (typeof propVal === 'string' && mmddyyyyRegex.test(propVal)) {
        modelNode[property] = new Date(propVal).toLocaleDateString();
      } else if (typeof propVal === 'object') {
        castNewDatesToLegacy(propVal);
      }
    }
  }
  return modelNode;
};

export const shouldRender = (field: FormlyFieldConfig, model, key) => {
  const conditions = field.props.condition;
  const conditionOptions = field.props.conditionOptions;

  return conditionOptions?.operator === '||'
    ? conditions.some(predicate(model, key))
    : conditions.every(predicate(model, key));
};

export const getParentModel = (model, key: string) => {
  let keyWithLastRemoved = key.substring(0, key.lastIndexOf('.'));
  return _.get(model, keyWithLastRemoved);
};

export const predicate = (model, key) => {
  return (obj) => {
    let shouldShow = null;
    const valueToCompare = obj.valueKey
      ? getValueOnObjectFromStringProperty(
          obj.valueKey,
          obj.valueKeyIsGlobal ? model : getParentModel(model, key)
        )
      : obj.value;
    let value = '';

    if (obj.key) {
      value = getValueOnObjectFromStringProperty(
        obj.key,
        obj.keyIsGlobal ? model : getParentModel(model, key)
      );
      switch (obj.operator) {
        case '!':
          shouldShow =
            valueToCompare === null
              ? value !== undefined && value !== null
              : formatForComparision(value) !==
                formatForComparision(valueToCompare);
          break;
        case '>':
          shouldShow =
            valueToCompare === null || value === undefined
              ? value !== undefined && value !== null
              : +value > +valueToCompare;
          break;
        case '<':
          shouldShow =
            valueToCompare === null || value === undefined
              ? value !== undefined && value !== null
              : +value < +valueToCompare;
          break;
        default:
          shouldShow =
            valueToCompare === null
              ? value === undefined || value === null
              : formatForComparision(value) ===
                formatForComparision(valueToCompare);
      }

      return shouldShow;
    } else {
      return valueToCompare;
    }
  };
};

export const formatForComparision = (val: unknown) => {
  if (typeof val === 'number') {
    return val.toFixed(6);
  } else {
    return val;
  }
};

export const States = {
  states: [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
};

// Functions below taken from
// SO post: https://stackoverflow.com/a/27747377
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
export const dec2hex = (dec) => {
  return dec.toString(16).padStart(2, '0');
};

// generateId :: Integer -> String
export const generateId = (len) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export const mapDataToFormlySchema = (
  data: Application,
  jsonFile: FormlyFieldConfig[],
  currentContent?: IApplicationContent,
  appType?: string
): IApplicationContent => {
  let updatedContentObject: IApplicationContent = currentContent
    ? _.cloneDeep(currentContent)
    : {};
  jsonFile[0]?.fieldGroup[0]?.fieldGroup?.forEach((x: any) => {
    if (x?.hooks?.onInit) {
      const onInitValue: string = x?.hooks?.onInit;
      const result: RegExpMatchArray = onInitValue.match(
        /formModelContent\((([^)]+))/
      );
      const key: string = x?.prepopulateAsync;
      const value: string | number | boolean =
        getValueOnObjectFromStringProperty(key, data);
      if (result && value) {
        setValueOnObjectFromStringProperty(
          result[1],
          updatedContentObject,
          value
        );
      }
    }
  });
  
  if(appType && Object.keys(updatedContentObject).length !== 0){
    updatedContentObject = formatAppContent(updatedContentObject, appType, data);
  }
  return updatedContentObject;
};

const formatAppContent = (appContent: IApplicationContent, appType: string, currentApplication: Application): IApplicationContent => {
  if(appType === "DistributedGenerationRebates"){
    appContent.rebate_information.has_associated_ic = currentApplication.id;
  }
  return appContent;
}