import { Injectable } from '@angular/core';
import { Permission } from '@intellio/shared/models';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private _rolePermissions: Record<string, Permission[]> = {};

  constructor(
    private accountService: AccountService,
    private appConfigService: AppConfigService
  ) {}

  get rolePermissions(): Record<string, Permission[]> {
    return { ...this._rolePermissions };
  }

  initRolePermissions(): Observable<boolean> {
    return forkJoin<[any, any]>([
      import('../assets/permission-maps/base.json'),
      import(`../assets/permission-maps/${this.appConfigService.tenant}.json`),
    ]).pipe(
      map(([basePermissionMap, tenantPermissionMap]) => {
        this._rolePermissions = {
          ...basePermissionMap,
          ...tenantPermissionMap,
        };
        delete this._rolePermissions.default;
        return true;
      })
    );
  }

  doesUserHaveAnyPermissions(permissions: Permission[]): boolean {
    const userPermissions = this.accountService.currentUser?.roles?.reduce<
      Set<Permission>
    >((prevRoleRes, curRole) => {
      this._rolePermissions[curRole.id]?.forEach((r) => prevRoleRes.add(r));
      return prevRoleRes;
    }, new Set());

    return permissions.some((perm) => userPermissions?.has(perm));
  }

  getDefaultApplicationSubView(): string {
    const userPermissions = this.accountService.currentUser?.roles.reduce<
      Set<Permission>
    >((prevRoleRes, curRole) => {
      this._rolePermissions[curRole.id]?.forEach((r) => prevRoleRes.add(r));
      return prevRoleRes;
    }, new Set());

    const options = [];
    for (const key of userPermissions.keys()) {
      switch (key) {
        case Permission.StatusHistoryRead:
          options.push('status-changes');
          break;
        case Permission.FormsRead:
          options.push('app-forms');
          break;
        default:
          continue;
      }
    }

    if (options.length > 0) {
      //otherwise return first match
      return options[0];
    }

    // default value
    return 'status-changes';
  }

  getDefaultAdminSubView(): string {
    const userPermissions = this.accountService.currentUser?.roles.reduce<
    Set<Permission>
    >((prevRoleRes, curRole) => {
      this._rolePermissions[curRole.id]?.forEach((r) => prevRoleRes.add(r));
      return prevRoleRes;
    }, new Set());

    const options = [];
    for (const key of userPermissions.keys()) {
      switch (key) {
        case Permission.AdminUsersRead:
          options.push('users');
          break;
        case Permission.AdminWorkflowsRead:
          options.push('forms');
          break;
        case Permission.AdminOperationsRead:
          options.push('operations');
          break;
        case Permission.AdminSystemLogRead:
          options.push('system');
          break;
        case Permission.AdminNotificationLogRead:
          options.push('notifications');
          break;
        case Permission.AdminAutomationLogRead:
          options.push('automation');
          break;
        case Permission.AdminModulesRead:
          options.push('modules');
          break;
        case Permission.AdminSecurityRead:
          options.push('security')
          break; 
        case Permission.AdminBrandingRead:
          options.push('branding')
          break; 
        case Permission.AdminScheudledNotificationsRead:
          options.push('scheduled')
          break; 
        default:
          continue;
      }
    }

    if (options.length > 0) {
      //otherwise return first match
      return options[0];
    }

    // default value
    return 'users';
  }
}
